import { Link } from "react-router-dom";

import Dropdown from "./Dropdown";
import ProfileMenu from "./ProfileMenu";

import { useProject } from "../contexts/new-project.context";

import logo from "../assets/png/logo.png";

import { NewProjectIcon } from "../assets/icons";

import { getItemFromStore } from "../utils/storage.util";

export default function Header() {
  const { isProjectsLoading, projects } = useProject();

  const runType = getItemFromStore("run_type");

  return (
    <header className="flex-none pe-8 py-4 flex items-center border-b-4 border-b-[#E7EBF1]">
      <div className="flex items-center gap-x-4 flex-1">
        <Link
          to={runType ? `/?run_type=${runType}` : "/"}
          className="w-40 h-16 block"
        >
          <img
            src={logo}
            alt="Logo of the Lens app"
            className="w-full h-full object-contain"
          />
        </Link>

        <Dropdown
          options={projects}
          LeftIcon={NewProjectIcon}
          isProjectsLoading={isProjectsLoading}
        />
      </div>

      <ProfileMenu />
    </header>
  );
}
