import classNames from "classnames";
import { useEffect, useState } from "react";

import { useProject } from "../../contexts/new-project.context";

import lightBg from "../../assets/png/light-bg.png";
import maleAvatar from "../../assets/png/male-avatar.png";
import personaSkeletonAvatar from "../../assets/png/persona-skeleton-avatar.png";

export default function PersonaDemographicDetails({
  isAiExpert = false,
  personaId,
  generatedPersonaDetails,
}) {
  const { selectedPersonas } = useProject();

  const [, setCurrentPersona] = useState(null);

  useEffect(() => {
    if (personaId) {
      setCurrentPersona(
        selectedPersonas.find((persona) => persona.persona_id === personaId)
      );
    }
    // eslint-disable-next-line
  }, [selectedPersonas, personaId]);

  let profileImageUrl = "";

  if (generatedPersonaDetails?.persona_info?.avatar_url) {
    profileImageUrl = generatedPersonaDetails?.persona_info?.avatar_url;
  } else if (isAiExpert) {
    profileImageUrl = maleAvatar;
  } else {
    profileImageUrl = personaSkeletonAvatar;
  }

  return (
    <div
      className={classNames(
        "p-6 rounded-xl z-10 relative border border-[#F7EAFB] bg-[#F7F2FB]"
      )}
    >
      <img
        src={lightBg}
        alt="Bulb icon"
        className={classNames("w-full h-full absolute inset-0 z-10 opacity-10")}
      />

      <div className="relative inset-0 z-20 flex max-lg:flex-wrap gap-x-10 gap-y-5">
        <div className="w-[167px] flex-none h-[167px] rounded-full bg-[#F1EAF6]">
          <img
            src={profileImageUrl}
            alt="Selected persona"
            className="w-full h-full object-contain rounded-full"
          />
        </div>

        <div
          className={classNames("flex-auto", { "self-center": !isAiExpert })}
        >
          <p className="w-[296px] mb-3 text-2xl font-semibold">
            <span>
              {isAiExpert
                ? "AI Expert"
                : generatedPersonaDetails?.persona_info?.persona_name}
            </span>
          </p>

          {isAiExpert ? (
            <p className="text-[#060606]">
              An advanced AI with specialised knowledge about packaging design
            </p>
          ) : null}

          <p className="text-[#060606]">
            {generatedPersonaDetails?.persona_info?.title || ""}
          </p>

          {!isAiExpert ? (
            <div className="flex [@media(max-width:1300px)]:flex-wrap gap-x-10 gap-y-5">
              <div className="w-[296px] flex-none space-y-1">
                {generatedPersonaDetails?.persona_info?.demographics
                  ?.slice(0, 3)
                  ?.map?.((item, index) => (
                    <p key={index} className={classNames("text-[#060606]")}>
                      <span className="flex-none font-semibold">
                        {item.name}:{" "}
                      </span>
                      <span className={classNames("border border-transparent")}>
                        {item.description}
                      </span>
                    </p>
                  ))}
              </div>

              <div className="flex-auto space-y-1">
                {generatedPersonaDetails?.persona_info?.demographics
                  ?.slice(3)
                  ?.map?.((item, index) => (
                    <p key={index} className={classNames("text-[#060606]")}>
                      <span className="flex-none font-semibold">
                        {item.name}:{" "}
                      </span>
                      <span className={classNames("border border-transparent")}>
                        {item.description}
                      </span>
                    </p>
                  ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
