import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from "../configs/firebase.config";

import ChatQna from "../components/chat/ChatQna";
import PersonaDetailsModal from "../components/modals/Persona-Details-Modal";

import { useProject } from "../contexts/new-project.context";
import { useChat } from "../contexts/chat.context";
import { useError } from "../contexts/error.context";

import {
  ChatMicIcon,
  ChatUploadIcon,
  NewChatMessageIcon,
  SampleQuestionIcon,
  SearchIcon,
  SubmitChatQuestionIcon,
} from "../assets/icons";

import MalePersonaImage from "../assets/png/male-persona.png";

export default function CreateChat() {
  const navigate = useNavigate();
  const { projectId, currentPersona } = useProject();

  const {
    setChatId,
    selectedStimuli,
    selectedVariants,
    isPersonaModalOpen,
    setIsPersonaModalOpen,
    setChatHistory,
    setChatData,
    handleChatDetails,
    isChatLoading,
    setIsChatLoading,
  } = useChat();
  const { handleAPIError } = useError();

  const [chatResponse, setChatResponse] = useState("");
  const [question, setQuestion] = useState("");
  const [qnaList, setQnaList] = useState([]);

  const handleChatSubmit = async (e, suggestedQuestion) => {
    if (e) e.preventDefault();

    if (!question && !suggestedQuestion) return;

    setQuestion("");
    setIsChatLoading(true);

    let qstn = question || suggestedQuestion;

    setQnaList((prev) => [
      ...prev,
      { question: qstn, answer: "", loading: true },
    ]);

    try {
      const accessToken = await auth.currentUser.getIdToken();

      const response = await fetch(
        `https://personas-be-e37xwkrbia-el.a.run.app/v1/projects/${projectId}/chat`,
        {
          method: "POST",
          body: JSON.stringify({
            persona_id: currentPersona?.persona_id,
            category: selectedStimuli?.field,
            image_hashes: selectedVariants,
            user_query: qstn,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const reader = response.body.getReader();
      let data = "";
      let currentChatId = "";

      while (true) {
        const { done, value } = await reader.read();

        if (done) break;

        const decodedText = new TextDecoder().decode(value);
        const jsonObjects = decodedText.match(/({[^}]*})/g);

        for (let i = 0; i < jsonObjects.length; i++) {
          const obj = jsonObjects[i];
          const messageObj = JSON.parse(obj);

          if (messageObj?.type === "chat_id") {
            currentChatId = messageObj?.chat_id;
            // eslint-disable-next-line
            setChatHistory((prev) => [
              { chatId: currentChatId, chatTitle: "New Interview" },
              ...prev,
            ]);
          }
          if (messageObj?.type === "message") {
            data += messageObj?.message;
          }
          if (messageObj?.type === "title") {
            // eslint-disable-next-line
            setChatHistory((prev) => {
              return prev.map((chatItem) =>
                chatItem.chatId === currentChatId
                  ? {
                      ...chatItem,
                      chatTitle: messageObj?.title || "New Interview",
                    }
                  : chatItem
              );
            });
          }
        }

        setChatResponse(data);
      }

      const newChatPayload = {
        category: selectedStimuli?.field,
        image_hashes: selectedVariants,
        persona_id: currentPersona?.persona_id,
        chats: [
          {
            id: 1,
            loading: false,
            user: qstn,
            assistant: data,
          },
        ],
      };

      const chatDetails = handleChatDetails(newChatPayload);

      setChatId(currentChatId);
      setChatData(chatDetails);

      // getChatHistory();

      setIsChatLoading(false);

      navigate(
        `/project/${projectId}/chat/${currentChatId}?shouldLoadChat=false`,
        { replace: true }
      );
    } catch (error) {
      handleAPIError(error);
    }
  };

  const closeModal = () => {
    setIsPersonaModalOpen(false);
  };

  const handleSampleQuestionClick = async (suggestedQuestion) => {
    setQuestion(suggestedQuestion);
    handleChatSubmit(null, suggestedQuestion);
  };

  useEffect(() => {
    if (qnaList.length) {
      setQnaList((prev) => {
        const prevData = [...prev];
        if (prevData[prevData.length - 1].loading) {
          prevData[prevData.length - 1].loading = false;
        }
        prevData[prevData.length - 1].answer = chatResponse;

        return prevData;
      });
    }
    // eslint-disable-next-line
  }, [chatResponse]);

  return (
    <>
      <div className="flex-auto flex flex-col overflow-auto">
        <div className="p-3 border-l border-b flex-none flex overflow-auto justify-between items-center gap-3">
          <div className="flex items-center gap-3 flex-none flex-wrap">
            <div className="flex-none w-10 h-10 relative rounded-full bg-[#F7F8FA]">
              <img
                src={currentPersona?.avatar_url || MalePersonaImage}
                alt={`${currentPersona?.persona_name} persona`}
                className="w-10 h-10 rounded-full bg-[#F7F8FA]"
              />

              <span className="w-3 h-3 absolute bottom-0 right-0 border-2 rounded-full border-white bg-[#33B379]"></span>
            </div>
            <div className="flex-none">
              <p
                onClick={() => setIsPersonaModalOpen(true)}
                className="text-[#7A3DAA] font-semibold cursor-pointer hover:underline hover:underline-offset-1"
              >
                {currentPersona?.persona_name}
              </p>
              {currentPersona?.consumer_segment_name ? (
                <p className="text-[#828282] text-xs">
                  ({currentPersona?.consumer_segment_name})
                </p>
              ) : null}
            </div>

            <div className="flex-none h-10 mx-3 w-px bg-[#E7EBF1]" />

            <div className="flex-none flex items-center gap-2 flex-wrap">
              <div className="text-sm font-medium flex items-center gap-2">
                <NewChatMessageIcon /> <span>New Interview</span>
              </div>
            </div>
          </div>
          <button className="mr-3 flex-none">
            <SearchIcon />
          </button>
        </div>

        <div className="flex-auto overflow-auto flex flex-col">
          {!qnaList.length ? (
            <div className="p-6 flex-auto flex justify-center items-center">
              <div className="max-w-[668px]">
                <p className="text-4xl text-[#99A8C0]">Ask me anything...</p>

                <div className="mt-10">
                  <p className="text-sm text-[#060606] flex items-center gap-x-2">
                    <SampleQuestionIcon />
                    <span>
                      Here are a few starter questions to get things rolling.
                    </span>
                  </p>
                </div>

                <div className="mt-5 grid lg:grid-cols-3 gap-4">
                  {[
                    "How did you come to know about this product?",
                    "Will you choose this brand over its competitors?",
                    "What kind of information or content do you find most helpful from similar brands?",
                  ].map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleSampleQuestionClick(item)}
                      className="p-5 rounded-xl cursor-pointer bg-[#F9F2FE]"
                    >
                      <p className="text-[10px] font-bold text-[#828282]">
                        Question {index + 1}
                      </p>
                      <p className="mt-2 text-sm text-[#060606]">{item}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <ChatQna qnaList={qnaList} />
          )}

          <div className="p-6 mt-5 flex-none">
            <form
              onSubmit={handleChatSubmit}
              className="px-5 flex flex-wrap gap-3 bg-[#F7F8FA] border rounded-lg shadow-md shadow-[#D2D9E5]"
            >
              <div className="flex-none self-center">
                <button
                  type="button"
                  className="w-6 h-6 rounded-full flex justify-center items-center"
                >
                  <ChatUploadIcon />
                </button>
              </div>
              <input
                autoFocus
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder={`Ask anything to ${currentPersona?.persona_name}...`}
                disabled={isChatLoading}
                className="py-4 flex-auto text-sm bg-transparent text-[#536787] outline-none placeholder:text-[#536787] placeholder:truncate truncate"
              />
              <div className="flex-none flex gap-2">
                <button
                  type="button"
                  className="w-6 h-6 self-center rounded-full flex justify-center items-center"
                >
                  <ChatMicIcon />
                </button>
                <button
                  disabled={isChatLoading}
                  type="submit"
                  className={classNames(
                    "w-6 h-6 self-center rounded-full flex justify-center items-center",
                    { "cursor-not-allowed": isChatLoading }
                  )}
                >
                  <SubmitChatQuestionIcon />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {isPersonaModalOpen ? (
        <PersonaDetailsModal closeModal={closeModal} />
      ) : null}
    </>
  );
}
