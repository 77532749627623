import classNames from "classnames";
import { useEffect, useState } from "react";

import DataLoader from "../Data-Loader";
import PersonaDemographicDetails from "../persona/PersonaDemographicDetails";

import { useProject } from "../../contexts/new-project.context";
import { useError } from "../../contexts/error.context";

import { httpRequest } from "../../utils/http.util";

import { CLoseIcon } from "../../assets/icons";

export default function PersonaDetailsModal({
  clickedPersonaId = null,
  closeModal,
}) {
  const { projectId, currentPersona } = useProject();
  const { handleAPIError } = useError();

  const personaId = currentPersona?.persona_id || clickedPersonaId;
  const isAiExpert = personaId === "ai_expert";

  const [generatedPersonaDetails, setGeneratedPersonaDetails] = useState(null);
  const [isLoadingPersonaDetails, setIsLoadingPersonaDetails] = useState(
    isAiExpert ? false : true
  );

  useEffect(() => {
    const main = async () => {
      if (projectId && personaId && personaId !== "ai_expert") {
        try {
          setIsLoadingPersonaDetails(true);

          const getPersonaDetailsResponse = await httpRequest({
            url: `/personas/${personaId}`,
            isAuthRequired: true,
          });

          setGeneratedPersonaDetails({
            persona_info: {
              ...getPersonaDetailsResponse.data,
            },
          });

          setIsLoadingPersonaDetails(false);
        } catch (error) {
          handleAPIError(error);
        }
      }
    };

    main();
    // eslint-disable-next-line
  }, [projectId, personaId]);

  return (
    <div className={classNames("absolute inset-0 z-50 bg-black bg-opacity-75")}>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-3/4 h-3/4 pt-12 pb-5 relative rounded-xl bg-white overflow-auto">
          <button
            onClick={closeModal}
            className="w-8 h-8 absolute top-4 right-4 inline-flex justify-center items-center rounded-full border z-30 border-[#E7EBF1] bg-white"
          >
            <CLoseIcon stroke="#536787" />
          </button>

          {isLoadingPersonaDetails ? (
            <DataLoader />
          ) : (
            <div className="px-10 space-y-10 w-full h-full overflow-auto">
              <PersonaDemographicDetails
                isAiExpert={isAiExpert}
                isEditable={false}
                personaId={personaId}
                generatedPersonaDetails={generatedPersonaDetails}
              />

              {!isAiExpert ? (
                <div className="px-3 py-5 border rounded-lg sticky top-0 z-10 bg-white border-[#F7E7FB]">
                  <p className="mb-3 text-xl font-semibold">
                    🚀 A quick summary about your persona
                  </p>
                  <p>{generatedPersonaDetails?.persona_info?.brief_summary}</p>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
