import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC1pYyfLxsD9DfgImBbbOyF1UO6Te20A3U",
  authDomain: "personas-426910.firebaseapp.com",
  projectId: "personas-426910",
  storageBucket: "personas-426910.appspot.com",
  messagingSenderId: "404517087479",
  appId: "1:404517087479:web:913f2d684ffcf93d010bf2",
  measurementId: "G-0SB8WESV7G",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export default app;
