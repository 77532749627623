import classNames from "classnames";
import { NavLink, Navigate, Outlet, useLocation } from "react-router-dom";

import StimuliSidebar from "../components/project-stimuli/StimuliSidebar";

import { useProject } from "../contexts/new-project.context";

import { AiPersonaInterviewsIcon } from "../assets/icons";

export default function ProjectStimuli() {
  const location = useLocation();

  const { projectId } = useProject();

  const isChatPage = location.pathname.includes("chat");

  if (!projectId) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="flex-auto flex overflow-auto">
      <StimuliSidebar />

      <div className="flex-auto overflow-auto flex flex-col">
        <div className="ps-[268px] flex-none h-11 flex items-center bg-[#F7F8FA]">
          <div className="flex items-center flex-wrap">
            <NavLink
              to="chat"
              className={({ isActive }) =>
                classNames(
                  "pb-2 inline-flex items-center gap-x-2 text-sm border-b-2",
                  isActive
                    ? "font-semibold border-[#7A3DAA] text-[#7A3DAA]"
                    : "font-medium border-transparent text-[#A8B6CA]"
                )
              }
            >
              <AiPersonaInterviewsIcon
                fill={isChatPage ? "#7A3DAA" : "#A8B6CA"}
              />
              <span>AI Persona Interviews</span>
            </NavLink>
          </div>
        </div>

        <Outlet />
      </div>
    </div>
  );
}
