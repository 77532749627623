import { createContext, useContext, useEffect, useState } from "react";

import { useAuth } from "./auth.context";
import { useError } from "./error.context";

import { httpRequest } from "../utils/http.util";

const ProjectContext = createContext();

export function ProjectContextProvider({ children }) {
  const { user } = useAuth();
  const { handleAPIError } = useError();

  const [projects, setProjects] = useState([]);
  const [isProjectsLoading, setIsProjectsLoading] = useState(true);
  const [projectId, setProjectId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [selectedPersonas, setSelectedPersonas] = useState([]);
  const [currentPersona, setCurrentPersona] = useState(null);

  const getSelectedPersonas = async (poll = false) => {
    let intervalId = null;

    const main = async () => {
      try {
        const response = await httpRequest({
          url: `/projects/${projectId}/personas`,
          isAuthRequired: true,
        });

        if (poll) {
          const containsPersonaWithoutAvatar = response.data.some(
            (persona) => !persona.avatar_url
          );

          if (!containsPersonaWithoutAvatar) {
            clearInterval(intervalId);
          }
        }

        setSelectedPersonas(response.data);
      } catch (error) {
        handleAPIError(error);
      }
    };

    main();

    if (poll) {
      intervalId = setInterval(main, 2000);
    }
  };

  useEffect(() => {
    if (user) {
      httpRequest({ url: "/projects/", isAuthRequired: true })
        .then((response) => {
          setIsProjectsLoading(false);
          setProjects(response.data.filter((item) => item.name));
        })
        .catch(handleAPIError)
        .finally(() => {
          setIsProjectsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user && projectId) {
      httpRequest({
        url: `/projects/${projectId}/name`,
        isAuthRequired: true,
      })
        .then((response) => {
          setProjectName(response.data);
        })
        .catch(handleAPIError);
    }
    // eslint-disable-next-line
  }, [user, projectId]);

  useEffect(() => {
    if (projectId && user) {
      getSelectedPersonas();
    }
    // eslint-disable-next-line
  }, [projectId, user]);

  const providerValue = {
    projects,
    setProjects,
    isProjectsLoading,
    setIsProjectsLoading,
    projectId,
    setProjectId,
    projectName,
    setProjectName,
    selectedPersonas,
    setSelectedPersonas,
    currentPersona,
    setCurrentPersona,
  };

  return (
    <ProjectContext.Provider value={providerValue}>
      {children}
    </ProjectContext.Provider>
  );
}

export const useProject = () => useContext(ProjectContext);
