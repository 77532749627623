import { useEffect, useState } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import GlobalLayout from "./layouts/global.layout";

import Login from "./pages/login.page";
import ProjectCreate from "./pages/project-create.page";
import ProjectStimuli from "./pages/project-stimuli.page";
import Chat from "./pages/chat.page";
import CreateChat from "./pages/create-chat.page";
import ChatView from "./pages/chat-view.page";
import NotFound from "./pages/not-found.page";

import Protected from "./components/Protected";

import ChatContextProvider from "./contexts/chat.context";

import { setItemToStore } from "./utils/storage.util";
import { useProject } from "./contexts/new-project.context";
import DataLoader from "./components/Data-Loader";

function Root() {
  const params = useParams();
  const { setProjectId, projectName } = useProject();
  const [loading, setLoading] = useState(true);

  const projectId = params?.projectId;

  useEffect(() => {
    if (projectName) {
      document.title = `${projectName} - Unmistakably Human`;
    } else {
      document.title = `Unmistakably Human`;
    }
  }, [projectName]);

  useEffect(() => {
    setLoading(true);
    if (projectId) {
      setProjectId(projectId);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1500);

    // eslint-disable-next-line
  }, [projectId]);

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <DataLoader />
      </div>
    );
  }

  return <Outlet />;
}

export default function App() {
  const location = useLocation();

  useEffect(() => {
    const parseUrlParams = (url) => {
      const params = {};
      const urlSearchParams = new URLSearchParams(url.search);
      for (const [key, value] of urlSearchParams) {
        params[key] = value;
      }
      return params;
    };

    const urlParams = parseUrlParams(location);

    for (const param in urlParams) {
      setItemToStore(param, urlParams[param]);
    }
  }, [location]);

  return (
    <Routes>
      <Route element={<Protected />}>
        <Route path="/" element={<GlobalLayout />}>
          <Route path="/" element={<ProjectCreate />} />
          <Route path="/" element={<Root />}>
            <Route path="/project/:projectId" element={<ProjectStimuli />}>
              <Route element={<ChatContextProvider />}>
                <Route path="chat" element={<Chat />}>
                  <Route path="new" element={<CreateChat />} />
                  <Route path=":chatId" element={<ChatView />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
