import classNames from "classnames";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { auth } from "../configs/firebase.config";

import Loader from "../components/chat/Loader";
import ChatQna from "../components/chat/ChatQna";
import PersonaDetailsModal from "../components/modals/Persona-Details-Modal";

import { useProject } from "../contexts/new-project.context";
import { useChat } from "../contexts/chat.context";
import { useError } from "../contexts/error.context";

import {
  ChatMicIcon,
  ChatUploadIcon,
  NewChatMessageIcon,
  SearchIcon,
  SubmitChatQuestionIcon,
} from "../assets/icons";

import MalePersonaImage from "../assets/png/male-persona.png";

export default function ChatView() {
  const { projectId, chatId } = useParams();
  const { currentPersona } = useProject();
  const {
    loadingChatData,
    chatData,
    setChatData,
    setChatHistory,
    isPersonaModalOpen,
    setIsPersonaModalOpen,
    chatHistory,
    isChatLoading,
    setIsChatLoading,
  } = useChat();
  const { handleAPIError } = useError();

  const [question, setQuestion] = useState("");
  const [chatResponse, setChatResponse] = useState("");

  const handleChatSubmit = async (e) => {
    e.preventDefault();

    if (!question) return;

    setQuestion("");
    setIsChatLoading(true);

    setChatData((prev) => {
      const newChat = {
        id: prev.chats.length + 1,
        user: question,
        assistant: "",
        loading: true,
      };
      return { ...prev, chats: [...prev.chats, newChat] };
    });

    try {
      const accessToken = await auth.currentUser.getIdToken();

      const response = await fetch(
        `https://personas-be-e37xwkrbia-el.a.run.app/v1/projects/${projectId}/chat/${chatId}`,
        {
          method: "POST",
          body: JSON.stringify(question),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setQuestion("");

      const reader = response.body.getReader();
      let data = "";

      while (true) {
        const { done, value } = await reader.read();

        if (done) break;

        const decodedText = new TextDecoder().decode(value);
        const jsonObjects = decodedText.match(/({[^}]*})/g);

        for (let i = 0; i < jsonObjects.length; i++) {
          const obj = jsonObjects[i];
          const messageObj = JSON.parse(obj);

          if (messageObj?.type === "message") {
            data += messageObj?.message;
          }
          if (messageObj?.type === "title") {
            // eslint-disable-next-line
            setChatHistory((prev) => {
              return prev.map((chatItem) =>
                chatItem.chatId === chatId
                  ? {
                      ...chatItem,
                      chatTitle: messageObj?.title || "New Interview",
                    }
                  : chatItem
              );
            });
          }
        }

        setChatResponse(data);
      }

      setIsChatLoading(false);
    } catch (error) {
      handleAPIError(error);
    }
  };

  const closeModal = () => {
    setIsPersonaModalOpen(false);
  };

  useEffect(() => {
    if (chatData?.chats?.length && chatResponse) {
      setChatData((prev) => {
        const chats = [...prev.chats];
        if (chats[chats.length - 1].loading) {
          chats[chats.length - 1].loading = false;
        }
        chats[chats.length - 1].assistant = chatResponse;

        return { ...prev, chats };
      });
    }
    // eslint-disable-next-line
  }, [chatResponse]);

  const currentChat = chatHistory.find((chat) => chat.chatId === chatId);

  return (
    <>
      <div className="flex-auto flex flex-col overflow-auto">
        {loadingChatData || !chatData ? (
          <Loader />
        ) : (
          <>
            <div className="p-3 border-b border-l flex-none flex overflow-auto justify-between items-center gap-3">
              <div className="flex items-center gap-3 flex-none flex-wrap">
                <div className="flex-none w-10 h-10 relative rounded-full bg-[#F7F8FA]">
                  <img
                    src={currentPersona?.avatar_url || MalePersonaImage}
                    alt={`${currentPersona?.persona_name} persona`}
                    className="w-10 h-10 rounded-full bg-[#F7F8FA]"
                  />

                  <span className="w-3 h-3 absolute bottom-0 right-0 border-2 rounded-full border-white bg-[#33B379]"></span>
                </div>
                <div className="flex-none">
                  <p
                    onClick={() => setIsPersonaModalOpen(true)}
                    className="text-[#7A3DAA] font-semibold cursor-pointer hover:underline hover:underline-offset-1"
                  >
                    {chatData?.persona?.persona_name}
                  </p>
                  {chatData?.persona?.consumer_segment_name ? (
                    <p className="text-[#828282] text-xs">
                      ({chatData?.persona?.consumer_segment_name})
                    </p>
                  ) : null}
                </div>

                <div className="flex-none h-10 mx-3 w-px bg-[#E7EBF1]" />

                <div className="flex-none flex items-center gap-2 flex-wrap">
                  <div className="text-sm font-medium flex items-center gap-2">
                    <NewChatMessageIcon />
                    <span>{currentChat?.chatTitle || "New Interview"}</span>
                  </div>
                </div>
              </div>
              <button className="mr-3 flex-none">
                <SearchIcon />
              </button>
            </div>

            <div className="flex-auto overflow-auto flex flex-col">
              <ChatQna qnaList={chatData?.chats} />

              <div className="p-6 mt-5 flex-none">
                <form
                  onSubmit={handleChatSubmit}
                  className="px-5 flex flex-wrap gap-3 bg-[#F7F8FA] border rounded-lg shadow-md shadow-[#D2D9E5]"
                >
                  <div className="flex-none self-center">
                    <button
                      type="button"
                      className="w-6 h-6 rounded-full flex justify-center items-center rotate-90"
                    >
                      <ChatUploadIcon />
                    </button>
                  </div>
                  <input
                    autoFocus
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder={`Ask anything to ${currentPersona?.persona_name}...`}
                    disabled={isChatLoading}
                    className="py-4 flex-auto text-sm bg-transparent text-[#536787] outline-none placeholder:text-[#536787] placeholder:truncate truncate"
                  />
                  <div className="flex-none flex gap-2">
                    <button
                      type="button"
                      className="w-6 h-6 self-center rounded-full flex justify-center items-center"
                    >
                      <ChatMicIcon />
                    </button>
                    <button
                      disabled={isChatLoading}
                      type="submit"
                      className={classNames(
                        "w-6 h-6 self-center rounded-full flex justify-center items-center",
                        { "cursor-not-allowed": isChatLoading }
                      )}
                    >
                      <SubmitChatQuestionIcon />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>

      {isPersonaModalOpen ? (
        <PersonaDetailsModal closeModal={closeModal} />
      ) : null}
    </>
  );
}
