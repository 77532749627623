import { Outlet } from "react-router-dom";

import Header from "../components/NewHeader";

export default function GlobalLayout() {
  return (
    <div className="h-screen flex flex-col overflow-auto">
      <Header />
      <Outlet />
    </div>
  );
}
