import axios from "axios";

import { auth } from "../configs/firebase.config";
import { wait } from "./promise.util";

const domains = {
  project: "https://personas-be-e37xwkrbia-el.a.run.app/v1",
  // projectDev: "https://kntr-be-dev-3m2lt2iata-el.a.run.app/v1",
  chat: "https://personas-be-e37xwkrbia-el.a.run.app/v1",
};

const axiosInstance = axios.create();
const maxRetries = 3;

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error?.code === "ERR_NETWORK" ||
      (error?.response?.status >= 500 && error?.response?.status <= 599)
    ) {
      const config = error.config;

      if (!config || !config.retry) {
        config.retry = 0;
      }

      await wait(1000);

      if (config.retry < maxRetries) {
        config.retry += 1;
        return axiosInstance(config);
      }
    }

    return Promise.reject(error);
  }
);

export async function httpRequest(options) {
  const config = {
    url: options.url,
    method: options.method || "GET",
    headers: options?.headers,
    data: options.data,
    baseURL: domains[options?.domain] || domains["project"],
  };

  if (options.isAuthRequired) {
    const accessToken = await auth.currentUser.getIdToken();

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return await axiosInstance(config);
}
