import { useEffect, useState } from "react";
import { useNavigate /* , useSearchParams */ } from "react-router-dom";

import Button from "../components/NewButton";

import { useAuth } from "../contexts/auth.context";
import { useError } from "../contexts/error.context";
import { useProject } from "../contexts/new-project.context";

import { httpRequest } from "../utils/http.util";
/* import { getItemFromStore } from "../utils/storage.util"; */

export default function ProjectCreate() {
  /* const [searchParams] = useSearchParams(); */

  /* const runType = searchParams.get("run_type");
  const runTypeFromLocalStore = getItemFromStore("run_type"); */

  const navigate = useNavigate();

  const [projectDetails, setProjectDetails] = useState({
    projectName: "",
  });

  const { user } = useAuth();
  const { handleAPIError } = useError();
  const {
    setProjectId,
    setProjectName,
    setCurrentPersona,
    setSelectedPersonas,
  } = useProject();

  const handleInputChanges = (e) => {
    const { name, value, files } = e.target;
    setProjectDetails((prev) => {
      if (name === "brandGuidelineFile") {
        return { ...prev, [name]: files?.[0] };
      }
      return { ...prev, [name]: value };
    });
  };

  const createProjectAndSaveName = async () => {
    try {
      const createdProjectId = await createProjectApi();
      await setProjectNameApi(createdProjectId, projectDetails.projectName);
      /* await updateProjectRunTypeApi(createdProjectId); */
      setProjectId(createdProjectId);
      setProjectName(projectDetails.projectName);
      navigate(`/project/${createdProjectId}/chat`);
    } catch (error) {
      handleAPIError(error);
    }
  };

  async function createProjectApi() {
    const response = await httpRequest({
      method: "POST",
      url: "/projects/",
      isAuthRequired: true,
    });

    return response.data;
  }

  /* async function updateProjectRunTypeApi(projectId) {
    await httpRequest({
      url: `/projects/${projectId}/run_type`,
      isAuthRequired: true,
      method: "PUT",
      data: JSON.stringify(runType || runTypeFromLocalStore || "full"),
      headers: { "Content-Type": "application/json" },
    });
  } */

  async function setProjectNameApi(projectId, projectName) {
    const response = await httpRequest({
      method: "PUT",
      url: `/projects/${projectId}/name`,
      isAuthRequired: true,
      data: JSON.stringify(projectName),
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  }

  useEffect(() => {
    setProjectId("");
    setProjectName("");
    setSelectedPersonas([]);
    setCurrentPersona(null);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex-auto overflow-auto flex flex-col">
      <div className="hidden-scroll mx-auto flex-auto w-full lg:w-[812px] overflow-auto py-8 space-y-8 px-3">
        <div className="space-y-4">
          <div className="text-[32px]">
            <span className="inline-flex items-center gap-x-2 font-semibold text-[#7A3DAA]">
              Hello, {user?.displayName}
              <img
                src="/png/Waving Hand.png"
                alt="Waving hand icon"
                className="w-8 h-8"
              />
            </span>

            <br />

            <span className="font-normal text-[#99A8C0]">
              let's set up your new project
            </span>
          </div>
        </div>

        <div className="space-y-8">
          <div>
            <p className="mb-2 font-medium">Project Name</p>

            <input
              value={projectDetails.projectName}
              name="projectName"
              onChange={handleInputChanges}
              placeholder="Enter project name here"
              className="w-full h-16 px-4 py-[10px] border-[1.5px] outline-none rounded-lg border-[#E7EBF1] bg-[#F7F8FA] focus:border-[#7A3DAA] focus:outline focus:outline-[#F1EAF6] hover:border-[#7A3DAA] hover:outline hover:outline-[#F1EAF6]"
            />
          </div>
        </div>
      </div>

      <div className="py-3 mx-auto w-full lg:w-[812px] flex-none text-right px-3">
        <Button
          title="Next"
          variant={projectDetails.projectName ? "primary" : "disabled"}
          type="submit"
          disabled={!projectDetails.projectName}
          onClick={createProjectAndSaveName}
        />
      </div>
    </div>
  );
}
