import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Persona from "./Persona";

import { useProject } from "../../contexts/new-project.context";

import { PlusIcon } from "../../assets/icons";

export default function StimuliSidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  const isChatPage = location.pathname.includes("chat");

  const { personas, selectedPersonas, currentPersona, setCurrentPersona } =
    useProject();

  const [stimuliHover, setStimuliHover] = useState({
    visible: false,
    imageSrc: "",
  });

  const choosePersona = (persona) => {
    setCurrentPersona(persona);

    if (isChatPage) {
      navigate("chat/new");
    }
  };

  useEffect(() => {
    setCurrentPersona(selectedPersonas[0]);
    // eslint-disable-next-line
  }, [personas, selectedPersonas]);

  return (
    <>
      <div className="w-72 overflow-auto flex-none flex flex-col border-r border-[#D2D9E5]">
        <div className="flex-auto overflow-auto flex flex-col">
          {/* PERSONAS START */}
          <div className="flex-none border-b border-[#D2D9E5]">
            <div className="px-4 h-11 flex justify-between items-center bg-[#F7F8FA]">
              <span className="text-sm font-semibold">PERSONAS</span>
            </div>

            <div className="h-[230px] px-4 py-3 overflow-auto space-y-5">
              {selectedPersonas.map((persona) => (
                <Persona
                  key={persona.persona_id}
                  persona={persona}
                  isSelected={persona.persona_id === currentPersona?.persona_id}
                  choosePersona={choosePersona}
                />
              ))}
            </div>
          </div>
          {/* PERSONAS END */}
        </div>

        <div className="p-4 flex-none">
          <Link
            to="chat/new"
            className="w-full p-3 flex items-center justify-center rounded-lg gap-2 text-white bg-[#7A3DAA] hover:bg-[#4E2670]"
          >
            <PlusIcon className="stroke-white" />
            <span>New Interview</span>
          </Link>
        </div>
      </div>

      <div
        onMouseOver={() => setStimuliHover({ ...stimuliHover, visible: true })}
        onMouseLeave={() => setStimuliHover({ visible: false, imageSrc: "" })}
        className={classNames(
          "w-[550px] h-[550px] fixed z-40 left-[300px] top-2/4 -translate-y-2/4 rounded-xl bg-white border shadow-2xl",
          stimuliHover.visible ? "block" : "hidden"
        )}
      >
        <img
          src={stimuliHover.imageSrc}
          alt="Stimuli input preview"
          className="w-full h-full object-contain rounded-lg"
        />
      </div>
    </>
  );
}
